export default () => ({
  menuOpen: false,
  subMenuOpen: false,

  init() {},

  // Fullscreen menu
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  },

  toggleSubMenu() {
    if (this.subMenuOpen) {
      return this.closeSubMenu()
    }
    this.$refs.subMenuLink.focus()
    this.subMenuOpen = true
  },

  closeSubMenu(focusAfter) {
    if (! this.subMenuOpen) return
    this.subMenuOpen = false
    this.currentSubMenu = null
    focusAfter && focusAfter.focus()
  }
})
