export default () => ({
  dropdownOpen: false,
  toggleDropdown() {
    if (this.dropdownOpen) {
      return this.closeDropdown()
    }
    this.$refs.button.focus()
    this.dropdownOpen = true
  },
  closeDropdown(focusAfter) {
    if (! this.dropdownOpen) return
    this.dropdownOpen = false
    focusAfter && focusAfter.focus()
  }
});
