import Alpine from 'alpinejs';

import dropdown from './ui/dropdown';
import navigation from './ui/navigation';

window.Alpine = Alpine;

Alpine.data('dropdown', dropdown);
Alpine.data('navigation', navigation);

Alpine.start();
